<template>
    <div id="index" ref="appRef">
        <div class="bg">
            <div class="top">
                <div class="bottom-left">
                    <dv-decoration-2 class="decoration-two1" />
                    <img src="../assets/img/bottom-icon2.png" alt="" class="bottom-img" />
                </div>
                <div class="bottom-center"></div>
                <div class="bottom-right">
                    <img src="../assets/img/bottom-icon1.png" alt="" class="bottom-img" />
                    <dv-decoration-2 class="decoration-two2" />
                </div>
            </div>
            <div class="center">
                <img src="../assets/img/login-logo.png" alt="" class="login-img">
                <div class="center-title">苹果产业数智大脑云管理系统</div>
                <div class="login-content">
                    <div class="login-title">用户登录</div>
                    <div class="input-item">
                        <img src="../assets/img/username.png" alt="">
                        <input v-model="userName" />
                    </div>
                    <div class="input-item">
                        <img src="../assets/img/password.png" alt="">
                        <input v-model="password" type="password" />
                    </div>
                    <div class="input-item">
                        <div class="code-item">
                            <img src="../assets/img/code.png" alt="" class="code-img">
                            <input v-model="code" class="code-input" maxlength="4" />
                        </div>
                        <div class="code" @click="refreshCode">
                            <Identify :identifyCode="identifyCode" :contentWidth="121" :contentHeight="46"></Identify>
                        </div>
                    </div>
                    <div class="remember">
                        <input v-model="checked" type="checkbox" class="input-checkbox" />
                        记住我
                    </div>
                    <button class="btn" @click="login">登录</button>
                </div>
            </div>
            <div class="footer">
          <div class="copyright">Copyright © 2025 农链科技 版权所有</div>
          <div class="footer-record"><a href='https://beian.miit.gov.cn/' target="_blank">陕ICP备19000833号-1</a></div>
            
        </div>
            <div class="bottom">
                <div class="bottom-left">
                    <dv-decoration-2 class="decoration-two1" />
                    <img src="../assets/img/bottom-icon2.png" alt="" class="bottom-img" />
                </div>
                <div class="bottom-center"></div>
                <div class="bottom-right">
                    <img src="../assets/img/bottom-icon1.png" alt="" class="bottom-img" />
                    <dv-decoration-2 class="decoration-two2" />
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import Identify from '../components/test/Identify.vue'
import drawMixin from "../utils/drawMixin";
export default {
    mixins: [drawMixin],
    name: 'login',
    components: {
        Identify
    },
    data() {
        return {
            // 随机抽取四位数
            identifyCodes: '1234567890abcdefghijklmnopqrstuvwxyz',
            identifyCode: '',
            checked: false,
            userName: '',//用户名
            password: '',//密码
            code: '',//验证码
        }
    },
    created(){
        if(localStorage.getItem('userName')&&localStorage.getItem('password')){
            this.userName=localStorage.getItem('userName')
            this.password=localStorage.getItem('password')
            this.checked=true
        }
    },
    mounted() {
        this.identifyCode = ''
        this.makeCode(this.identifyCodes, 4)
    },
    methods: {
        login() {
            if (this.userName == 'admin' && this.password == '123456' && this.code == this.identifyCode) {
                this.$message({
                    message: '登录成功',
                    type: 'success'
                });
                if(this.checked==true){
                    localStorage.setItem('userName',this.userName)
                    localStorage.setItem('password',this.password)
                }
                this.$router.push('/index')
            } else {
                if (this.userName == 'admin' && this.password == '123456' && this.code != this.identifyCode) {
                    this.$message({
                        message: '验证码错误，请重新输入',
                        type: 'error'
                    });
                    this.refreshCode()
                }else{
                    this.$message({
                        message: '用户名或密码错误，请检查后重新输入',
                        type: 'error'
                    });
                }
            }
        },
        // 验证码处理
        randomNum(min, max) {
            return Math.floor(Math.random() * (max - min) + min)
        },
        // 刷新验证码
        refreshCode() {
            this.identifyCode = ''
            this.makeCode(this.identifyCodes, 4)
        },
        makeCode(o, l) {
            for (let i = 0; i < l; i++) {
                this.identifyCode += this.identifyCodes[
                    this.randomNum(0, this.identifyCodes.length)
                ]
            }
            console.log(this.identifyCode)
        }
    }
}
</script>
  
<style lang="scss" scoped>
@import '../assets/scss/login.scss';
</style>